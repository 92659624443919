import type { Button } from '@repo/ui/components/Button.js'
import { Form, SubmitButton } from '@repo/ui/components/Forms.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import type { z } from 'zod'
import { useForm } from '~/hooks/useForm'
import { LoginSchema } from '~/routes/_auth.login/routeConfig'

export const LoginButton = ({
  className,
  provider,
  redirectTo,
  ...props
}: ComponentProps<typeof Button> & {
  provider: z.infer<typeof LoginSchema>['provider']
  redirectTo?: string
}) => {
  const form = useForm({
    id: provider,
    schema: LoginSchema,
    action: '/login',
    withFetcher: true
  })

  return (
    <Form {...form.getFormProps()}>
      <input type="hidden" name={form.fields.provider} value={provider} />

      <input
        type="hidden"
        name={form.fields.timezone}
        value={Intl.DateTimeFormat().resolvedOptions().timeZone}
      />
      <input type="hidden" name={form.fields.redirectTo} value={redirectTo} />

      <SubmitButton
        variant="outline"
        className={cn('w-full', className)}
        {...props}
      >
        <Icon
          name={provider === 'google' ? 'google-color' : 'microsoft-color'}
        />
        <span>
          Sign in with <span className="capitalize">{provider}</span>
        </span>
      </SubmitButton>
    </Form>
  )
}
